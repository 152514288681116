import { LockOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext, firebase, Logo } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    sidebar: {
      background: theme.lightBg,
      height: "100vh"
    },
    menuBlock: {
      height: "100%"
    },
    logo: {
      margin: 24,
      marginBottom: 28
    },
    sidebarIcon: {
      minWidth: 14,
      marginRight: 10
    }
  };
});

const Sidebar = () => {
  const { setUser } = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const keys = ["users", "teams", "sign-out"];

  const logout = async () => {
    await firebase.auth().signOut();
    localStorage.clear();
    setUser(null);
    history.push("/signin");
  };

  return (
    <Layout.Sider className={classes.sidebar}>
      <div className={classes.logo}>
        <Logo color="black" />
      </div>
      <Menu
        className={classes.menuBlock}
        mode="inline"
        defaultSelectedKeys={[
          keys.find(val => {
            if (location.pathname === "/") return "users";
            return location.pathname.includes(val);
          }) || ""
        ]}
      >
        <Menu.Item icon={<UserOutlined />} key="users">
          <Link to={"/users"}>{t("Users")}</Link>
        </Menu.Item>
        <Menu.Item icon={<TeamOutlined />} key="teams">
          <Link to={"/teams"}>{t("Teams")}</Link>
        </Menu.Item>
        <Menu.Item icon={<LockOutlined />} onClick={logout} key="sign-out">
          {t("Sign out")}
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};
export { Sidebar };
