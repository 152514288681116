import { Layout } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Redirect, Switch } from "react-router-dom";

import { Sidebar } from "./components/pages/Layout/Sidebar";
import { TeamForm } from "./components/pages/Team/TeamForm";
import { TeamList } from "./components/pages/Team/TeamList";
import { UserPage } from "./components/pages/UserPage";
import { UserDetail } from "./components/pages/UserPage/UserDetail";
import { PrivateRoute } from "./PrivateRoute";

const useStyles = createUseStyles(() => {
  return {
    container: {
      height: "-webkit-fill-available",
      width: "100%",
      display: "flex",
      flexDirection: "row"
    }
  };
});

const Home = () => {
  const classes = useStyles();
  return (
    <Layout className={classes.container}>
      <Sidebar />
      <Switch>
        <PrivateRoute path="/teams/edit/:id" component={TeamForm} />
        <PrivateRoute path="/teams/add" component={TeamForm} />
        <PrivateRoute path="/teams" component={TeamList} />
        <PrivateRoute path="/users/:id" component={UserDetail} />
        <PrivateRoute path="/users" component={UserPage} />
        <Redirect to="/users" from="/" />
      </Switch>
    </Layout>
  );
};

export { Home };
