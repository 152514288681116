import { MailOutlined, UnlockOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/browser";
import { Button, Col, Form, message, Row } from "antd";
import React, { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  captureMessage,
  ErrorMessages,
  firebase,
  Logo,
  TextField,
  Typography
} from "shared-components";

import { Footer } from "../Layout/Footer";

const useStyles = createUseStyles((theme: any) => {
  return {
    row: {
      paddingTop: 100,
      background: theme.gray3,
      height: "-webkit-fill-available",
      width: "100%",
      marginBottom: 0
    },

    formError: {
      color: theme.textRed
    },
    formWrapper: {
      display: "flex",
      justifyContent: "center"
    },
    btnWrapper: {
      display: "flex",
      justifyContent: "center"
    },
    placeholderIcon: {
      color: theme.blue6
    },
    logoWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 40,
      flexDirection: "column",
      alignItems: "center"
    },
    logoTitle: {
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: 18,
      lineHeight: "27px",
      color: theme.textPrimary
    },
    form: {
      padding: "24px",
      background: theme.lightBg
    },
    submitButtonItem: {
      marginBottom: 0
    },
    title: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "28px",
      color: theme.textPrimary,
      marginBottom: 24
    },
    loaderWrapper: {
      width: "100%",
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    button: {
      borderRadius: 2,
      background: theme.blue6,
      border: "none",
      padding: "8px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 40
    },
    text: {
      minHeight: 40,
      minWidth: 282
    }
  };
});

const SignIn = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, setState] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false as boolean);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(s => {
      return { ...s, [name]: value };
    });
  };

  const handleSignIn = useCallback(async () => {
    setLoading(true);
    const { email, password } = state;

    try {
      const token = await executeRecaptcha!("adminSignIn");
      const recaptchaData: any = await API.post(`verify-recaptcha`, {
        response: token
      });
      if (
        recaptchaData &&
        recaptchaData.data &&
        recaptchaData.data.score > 0.5
      ) {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      captureMessage(
        `Authentication error: ${JSON.stringify(error)}`,
        Sentry.Severity.Fatal
      );
      if (error.code && ErrorMessages[error.code]) {
        message.error(t(ErrorMessages[error.code]));
      }
    }
  }, [state]);

  return (
    <Row className={classes.row}>
      <Col span={24} style={{ minHeight: "80vh" }}>
        <div className={classes.logoWrapper}>
          <Logo color="black" textSize="large" />
        </div>
        <div className={classes.formWrapper}>
          <Form className={classes.form} name="basic" onFinish={handleSignIn}>
            <Typography className={classes.title} center type="boldBody">
              {t("Admin Dashboard")}
            </Typography>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Required")
                },
                { type: "email", message: t("Invalid format") }
              ]}
            >
              <TextField
                name="email"
                placeholder={t("Email")}
                onChange={handleChange}
                className={classes.text}
                icon={<MailOutlined className={classes.placeholderIcon} />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: t("Required") },
                {
                  min: 8,
                  message: t("Must be at least 8 characters")
                }
              ]}
            >
              <TextField
                name="password"
                type="password"
                placeholder={t("Password")}
                onChange={handleChange}
                className={classes.text}
                icon={<UnlockOutlined className={classes.placeholderIcon} />}
              />
            </Form.Item>

            <Form.Item className={classes.submitButtonItem}>
              <div className={classes.btnWrapper}>
                <Button
                  block
                  className={classes.button}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("Sign In")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Col>
      <Footer />
    </Row>
  );
};

export { SignIn };
