import { SearchOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { exceptionHandler } from "shared-components";
import { APIV2 } from "shared-components/src/api/apiV2";

import {
  LoadErrorComponent,
  NoDataComponent
} from "../../atoms/InfoComponent/index.stories";
import { ComponentWrapper } from "../Layout/ComponentWrapper";

const useStyles = createUseStyles((theme: any) => {
  return {
    tableWrapper: {
      overflow: "scroll",
      padding: "24px",
      background: "white",
      height: "-webkit-fill-available",
      "& th": {
        fontWeight: "bold !important",
        borderBottom: `1px solid ${theme.gray4} !important`,
        color: theme.gray9
      },
      "& td": {
        color: theme.gray8,
        borderBottom: `1px solid ${theme.gray4} !important`,
        "& a": {
          color: theme.blue6
        }
      },
      "& .ant-pagination-item-link": {
        borderRadius: 35
      },
      "& li": {
        borderRadius: 35
      }
    },
    container: {
      margin: 20
    },
    loaderWrapper: {
      width: "100%",
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  };
});

const UserPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [userList, setUserList] = useState([] as UserTableItem[]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true as boolean);

  // ユーザー数が増えてきたらページネーションにバックエンド連携が必要
  // 現状は、全レコード呼び出し＆フロントでページネーション
  // フィルター、ソートもフロントエンドで実施
  // readPage: 100件ずつ反復読み込みする際の反復数
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 20,
    total: 0
  });

  useEffect(() => {
    getUserListTotalCount();
  }, []);

  useEffect(() => {
    pagination.total && getUserListsRepeatedly();
  }, [pagination.total]);

  const getUserListTotalCount = async () => {
    // Total件数を取得（暫定）
    const response: any = await getUserLists(
      pagination.page,
      pagination.pageSize
    );
    setPagination({ ...pagination, total: response.total_count });
  };

  const getUserListsRepeatedly = async () => {
    // Total件数まで反復的に取得（暫定）
    const READ_SIZE_PER_REQUEST = 100;
    let tmpUserList: UserTableItem[] = [];
    for (
      let readPage = 1;
      readPage <= Math.ceil(pagination.total / READ_SIZE_PER_REQUEST);
      readPage++
    ) {
      // 取得したユーザーリストを既存のリストに追加
      const response = await getUserLists(readPage, READ_SIZE_PER_REQUEST);
      tmpUserList = tmpUserList.concat(
        response.users.map((data: UserResponseItem) => {
          return {
            key: data.id,
            id: data.id,
            name: data.name,
            email: data.email,
            created_at: data.created_at,
            updated_at: data.updated_at,
            notification_read_at: data.notification_read_at,
            last_logged_in_at: data.last_logged_in_at,
            last_refreshed_at: data.last_refreshed_at,
            language: data.language,
            occupation: data.occupation
          };
        })
      );
    }
    setUserList(tmpUserList);
  };

  const getUserLists = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response: any = await APIV2.get(`admin/users`, {
        params: {
          page: page,
          pageSize: pageSize
        }
      });
      setLoading(false);
      return response;
      // const response = dummyUsers;
      // if (response && response.users) {
      //   setUserList(
      //     response.users.map((data: UserResponseItem) => {
      //       return {
      //         key: data.id,
      //         id: data.id,
      //         name: data.name,
      //         email: data.email,
      //         created_at: data.created_at,
      //         updated_at: data.updated_at,
      //         notification_read_at: data.notification_read_at,
      //         last_logged_in_at: data.last_logged_in_at,
      //         last_refreshed_at: data.last_refreshed_at,
      //         language: data.language,
      //         occupation: data.occupation
      //       };
      //     })
      //   );
      //   setPagination({
      //     ...pagination,
      //     pageSize: response.total_count > 100 ? 100 : response.total_count,
      //     total: response.total_count
      //   });
      // }
      // setLoading(false);
      // return response.users.map((data: UserResponseItem) => {
      //   return {
      //     key: data.id,
      //     id: data.id,
      //     name: data.name,
      //     email: data.email,
      //     created_at: data.created_at,
      //     updated_at: data.updated_at,
      //     notification_read_at: data.notification_read_at,
      //     last_logged_in_at: data.last_logged_in_at,
      //     last_refreshed_at: data.last_refreshed_at,
      //     language: data.language,
      //     occupation: data.occupation
      //   };
      // });
    } catch (error) {
      exceptionHandler(error, t);
      setError(true);
      setLoading(false);
      return [];
    }
  };

  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm }: any) => {
    return (
      <div>
        <Input
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: t("User name"),
      dataIndex: "name",
      key: "name",
      sorter: (a: UserResponseItem, b: UserResponseItem) => {
        return a.name.localeCompare(b.name);
      },
      onFilter: (value: any, record: UserResponseItem) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
      filterDropdown: filterDropdown,
      filterIcon: () => {
        return <SearchOutlined />;
      }
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      sorter: (a: UserResponseItem, b: UserResponseItem) => {
        return a.email.localeCompare(b.email);
      },
      onFilter: (value: any, record: UserResponseItem) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
      filterDropdown: filterDropdown,
      filterIcon: () => {
        return <SearchOutlined />;
      }
    },
    {
      title: t("Created at"),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a: UserResponseItem, b: UserResponseItem) => {
        return a.created_at.localeCompare(b.created_at);
      }
    },
    {
      title: t("Updated at"),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a: UserResponseItem, b: UserResponseItem) => {
        return a.updated_at.localeCompare(b.updated_at);
      }
    },
    {
      title: t("LastLogInTimestamp"),
      dataIndex: "last_logged_in_at",
      key: "last_logged_in_at",
      sorter: (a: UserResponseItem, b: UserResponseItem) => {
        return a.last_logged_in_at.localeCompare(b.last_logged_in_at);
      }
    },
    {
      title: t("LastRefreshTimestamp"),
      dataIndex: "last_refreshed_at",
      key: "last_refreshed_at",
      sorter: (a: UserResponseItem, b: UserResponseItem) => {
        return a.last_refreshed_at.localeCompare(b.last_refreshed_at);
      }
    },
    {
      title: t("Language"),
      dataIndex: "language",
      key: "language",
      filters: [
        {
          text: t("en"),
          value: "en"
        },
        {
          text: t("ja"),
          value: "ja"
        }
      ],
      onFilter: (value: any, record: UserResponseItem) => {
        return record.language.indexOf(value) === 0;
      },
      render: (text: string) => {
        return t(text);
      }
    },
    {
      title: t("Occupation"),
      dataIndex: "occupation",
      key: "occupation",
      sorter: (a: UserResponseItem, b: UserResponseItem) => {
        return a.occupation.localeCompare(b.occupation);
      }
    },
    {
      title: t("Action"),
      key: "action",
      render: (text: string, data: UserTableItem) => {
        return (
          <Link
            to={{
              pathname: `users/${data.id}`,
              state: data
            }}
          >
            {t("Details")}
          </Link>
        );
      }
    }
  ];

  return (
    <ComponentWrapper title="Users">
      <div className={classes.container}>
        {error ? (
          <LoadErrorComponent />
        ) : (
          <div className={classes.tableWrapper}>
            {!loading && userList.length === 0 ? (
              <NoDataComponent />
            ) : (
              <Table
                loading={loading}
                dataSource={userList}
                columns={columns}
              />
            )}
          </div>
        )}
      </div>
    </ComponentWrapper>
  );
};

export { UserPage };
