import { Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router";
import { APIV2, exceptionHandler } from "shared-components";

import { ComponentWrapper } from "../Layout/ComponentWrapper";

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      marginBottom: 24,
      "& label": {
        fontSize: "14px",
        lineHeight: "22px",
        color: theme.gray9,
        fontWeight: "normal",
        marginBottom: 2
      },

      "& .ant-form-item-explain": {
        color: theme.red6,
        marginTop: 2
      },
      "& input": {
        fontSize: "14px"
      }
    },
    fullWidth: {
      maxWidth: "100%",
      borderRadius: 2
    },
    formWrapper: {
      padding: 24,
      background: "white",
      height: "-webkit-fill-available"
    },
    button: {
      borderRadius: 35,
      background: theme.blue6,
      borderColor: theme.blue6
    },
    container: {
      margin: 20
    },
    updateContainer: {
      width: "100%",
      height: 676,
      margin: 20
    },
    ul: {
      padding: 0,
      listStyleType: "none"
    },
    li: {
      paddingBottom: 5
    }
  };
});

const UserDetail = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id }: any = useParams();
  const [form] = Form.useForm();

  const [state, setState] = useState<UserDetail>({
    key: "",
    user_id: "",
    name: "",
    display_name: "",
    email: "",
    created_at: "",
    updated_at: "",
    notification_read_at: "",
    last_logged_in_at: "",
    last_refreshed_at: "",
    language: "",
    occupation: "",
    specialties: [],
    qualifications: [],
    work_career: [],
    school_career: []
  });

  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({ ...state, language: t(state.language) });
  }, [state]);

  const fetchData = async (id: string) => {
    // setLoading(true);
    try {
      const response: UserProfileResponse = await APIV2.get(
        `users/${id}/profile`
      );
      if (response) {
        setState({
          ...state,
          user_id: response.user_id,
          name:
            props.location && props.location.state && props.location.state.name,
          display_name: response.display_name,
          email:
            props.location &&
            props.location.state &&
            props.location.state.email,
          created_at:
            props.location &&
            props.location.state &&
            props.location.state.created_at,
          updated_at:
            props.location &&
            props.location.state &&
            props.location.state.updated_at,
          notification_read_at:
            props.location &&
            props.location.state &&
            props.location.state.notification_read_at,
          last_logged_in_at:
            props.location &&
            props.location.state &&
            props.location.state.last_logged_in_at,
          last_refreshed_at:
            props.location &&
            props.location.state &&
            props.location.state.last_refreshed_at,
          language:
            props.location &&
            props.location.state &&
            props.location.state.language,
          occupation: response.occupation,
          specialties: response.specialties,
          qualifications: response.qualifications,
          work_career: response.work_career,
          school_career: response.school_career
        });
        // form.setFieldsValue({
        //   owner_name: response.data.user.name,
        //   team_name: response.data.team.name,
        //   email: response.data.user.email,
        //   external_team: response.data.team.external_team
        // });
        // setLoading(false);
      }
    } catch (error) {
      exceptionHandler(error, t);
      // setLoading(false);
    }
  };

  return (
    <ComponentWrapper back title={id ? "Edit Team" : "Add Team"}>
      <div className={classes.container}>
        <div className={classes.formWrapper}>
          <Row>
            <Col lg={8}>
              <Form layout="vertical" scrollToFirstError form={form}>
                <Form.Item
                  required={false}
                  name="name"
                  label={t("User name")}
                  className={classes.formItem}
                >
                  <Input
                    name="name"
                    data-testid="name"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>
                <Form.Item
                  required={false}
                  name="display_name"
                  label={t("Display name")}
                  className={classes.formItem}
                >
                  <Input
                    name="display_name"
                    data-testid="display_name"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>

                <Form.Item
                  required={false}
                  name="email"
                  label={t("Email")}
                  className={classes.formItem}
                >
                  <Input
                    name="email"
                    data-testid="email"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>
                <Form.Item
                  id="created_at"
                  required={false}
                  name="created_at"
                  label={t("Created at")}
                  className={classes.formItem}
                >
                  <Input
                    name="created_at"
                    data-testid="created_at"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>
                <Form.Item
                  label={t("Updated at")}
                  name="updated_at"
                  required={false}
                  className={classes.formItem}
                >
                  <Input
                    name="updated_at"
                    data-testid="updated_at"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>

                <Form.Item
                  id="notification_read_at"
                  required={false}
                  name="notification_read_at"
                  label={t("NotificationReadAt")}
                  className={classes.formItem}
                >
                  <Input
                    name="notification_read_at"
                    data-testid="notification_read_at"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>
                <Form.Item
                  id="notification_read_at"
                  required={false}
                  name="notification_read_at"
                  label={t("LastLogInTimestamp")}
                  className={classes.formItem}
                >
                  <Input
                    name="notification_read_at"
                    data-testid="notification_read_at"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>
                <Form.Item
                  id="last_refreshed_at"
                  required={false}
                  name="last_refreshed_at"
                  label={t("LastRefreshTimestamp")}
                  className={classes.formItem}
                >
                  <Input
                    name="last_refreshed_at"
                    data-testid="last_refreshed_at"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>

                <Form.Item
                  id="language"
                  required={false}
                  name="language"
                  label={t("Language")}
                  className={classes.formItem}
                >
                  <Input
                    name="language"
                    data-testid="language"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>

                <Form.Item
                  id="occupation"
                  required={false}
                  name="occupation"
                  label={t("Occupation")}
                  className={classes.formItem}
                >
                  <Input
                    name="occupation"
                    data-testid="occupation"
                    className={classes.fullWidth}
                    readOnly={true}
                  />
                </Form.Item>

                <Form.Item
                  id="specialties"
                  required={false}
                  label={t("Specialities")}
                  className={classes.formItem}
                  shouldUpdate={true}
                >
                  {({ getFieldValue }) => {
                    const data: string[] = getFieldValue("specialties") || [];
                    return data.length ? (
                      <ul className={classes.ul}>
                        {data.map((item, index) => {
                          return (
                            <li key={index} className={classes.li}>
                              <Input
                                name={"specialties" + index.toString()}
                                data-testid={"specialties" + index.toString()}
                                className={classes.fullWidth}
                                readOnly={true}
                                value={item}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <Typography.Text
                        className="ant-form-text"
                        type="secondary"
                      >
                        {t("No Value")}
                      </Typography.Text>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  id="qualifications"
                  required={false}
                  label={t("Qualifications")}
                  className={classes.formItem}
                  shouldUpdate={true}
                >
                  {({ getFieldValue }) => {
                    const data: string[] =
                      getFieldValue("qualifications") || [];
                    return data.length ? (
                      <ul className={classes.ul}>
                        {data.map((item, index) => {
                          return (
                            <li key={index} className={classes.li}>
                              <Input
                                name={"qualifications" + index.toString()}
                                data-testid={
                                  "qualifications" + index.toString()
                                }
                                className={classes.fullWidth}
                                readOnly={true}
                                value={item}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <Typography.Text
                        className="ant-form-text"
                        type="secondary"
                      >
                        {t("No Value")}
                      </Typography.Text>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  id="work_career"
                  required={false}
                  label={t("Work career")}
                  className={classes.formItem}
                  shouldUpdate={true}
                >
                  {({ getFieldValue }) => {
                    const data: string[] = getFieldValue("work_career") || [];
                    return data.length ? (
                      <ul className={classes.ul}>
                        {data.map((item, index) => {
                          return (
                            <li key={index} className={classes.li}>
                              <Input
                                name={"work_career" + index.toString()}
                                data-testid={"work_career" + index.toString()}
                                className={classes.fullWidth}
                                readOnly={true}
                                value={item}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <Typography.Text
                        className="ant-form-text"
                        type="secondary"
                      >
                        {t("No Value")}
                      </Typography.Text>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  id="school_career"
                  required={false}
                  label={t("School career")}
                  className={classes.formItem}
                  shouldUpdate={true}
                >
                  {({ getFieldValue }) => {
                    const data: string[] = getFieldValue("school_career") || [];
                    return data.length ? (
                      <ul className={classes.ul}>
                        {data.map((item, index) => {
                          return (
                            <li key={index} className={classes.li}>
                              <Input
                                name={"school_career" + index.toString()}
                                data-testid={"school_career" + index.toString()}
                                className={classes.fullWidth}
                                readOnly={true}
                                value={item}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <Typography.Text
                        className="ant-form-text"
                        type="secondary"
                      >
                        {t("No Value")}
                      </Typography.Text>
                    );
                  }}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </ComponentWrapper>
  );
};

export { UserDetail };
