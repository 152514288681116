import { message } from "antd";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Redirect, Route, useHistory } from "react-router-dom";
import { AuthContext, firebase, Loader } from "shared-components";

const useStyles = createUseStyles(() => {
  return {
    loader: {
      marginLeft: "45%",
      marginTop: "20%"
    }
  };
});

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const { loading, authenticatedAdmin, user } = useContext(AuthContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      checkRole();
    }
  }, [user]);

  const checkRole = async () => {
    const idTokenResult = await user!.getIdTokenResult();

    if (!idTokenResult.claims.admin) {
      await firebase.auth().signOut();
      history.push("/");
      message.error(t("This account does not belong to admin"));
    }
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (!authenticatedAdmin) {
          return (
            <Redirect
              to={{ pathname: "/sign-in", state: { prevPath: rest.path } }}
            />
          );
        }

        if (user && user.email === null) {
          return (
            <Redirect
              to={{ pathname: "/sign-in", state: { prevPath: rest.path } }}
            />
          );
        }

        return <RouteComponent {...routeProps} />;
      }}
    />
  );
};

export { PrivateRoute };
