import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { Redirect, Route } from "react-router-dom";
import { AuthContext, Loader } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    loader: {
      marginLeft: "45%",
      marginTop: "20%"
    }
  };
});

const RestrictedRoute = ({ component: RouteComponent, ...rest }: any) => {
  const { loading, authenticatedAdmin } = useContext(AuthContext);
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (authenticatedAdmin) {
          return (
            <Redirect to={{ pathname: "/", state: { prevPath: rest.path } }} />
          );
        }

        return <RouteComponent {...routeProps} />;
      }}
    />
  );
};

export { RestrictedRoute };
