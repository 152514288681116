import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons/lib";
import React from "react";
import { i18n } from "shared-components/src/i18n";

import { InfoComponent } from "./InfoComponent";

export const NoDataYet = () => {
  return (
    <InfoComponent
      icon={InfoCircleOutlined}
      title={i18n.t("There are no data to see yet.")}
    />
  );
};

export const LoadError = () => {
  return (
    <InfoComponent
      color="error"
      icon={CloseCircleOutlined}
      title={i18n.t("Failed to load data.")}
      subtitle={i18n.t("Please try again later.")}
    />
  );
};
