import { CheckOutlined } from "@ant-design/icons";
import { text } from "@storybook/addon-knobs";
import React from "react";

import { LoadError, NoDataYet } from "./index";
import { InfoComponent } from "./InfoComponent";

export default {
  component: InfoComponent,
  title: "Components/Atoms/InfoComponent"
};

export const Component = () => {
  return (
    <InfoComponent
      icon={CheckOutlined}
      title={text("Title", "There are no data to see yet.")}
      subtitle={text("SubTitle", "")}
    />
  );
};

export const LoadErrorComponent = () => {
  return <LoadError />;
};

export const NoDataComponent = () => {
  return <NoDataYet />;
};
