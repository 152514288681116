import "antd/dist/antd.less";

import { Layout } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider } from "react-jss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  AuthProvider,
  Forbidden,
  initSentry,
  NotFound,
  RECAPTCHA_SITE_KEY,
  theme
} from "shared-components";

import { SignIn } from "./components/pages/Auth/SignIn";
import { Home } from "./Home";
import { PrivateRoute } from "./PrivateRoute";
import { RestrictedRoute } from "./RestrictedRoute";

initSentry();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Layout style={{ height: "100vh" }}>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path="/403" component={Forbidden} />
            <Route exact path="/404" component={NotFound} />
            <RestrictedRoute
              exact
              path="/sign-in"
              component={() => {
                return (
                  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                    <SignIn />
                  </GoogleReCaptchaProvider>
                );
              }}
            />
            <PrivateRoute path="/" component={Home} />
          </Switch>
        </AuthProvider>
      </Router>
    </Layout>
  </ThemeProvider>,
  document.getElementById("root")
);
