import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      padding: 50,
      textAlign: "center",
      height: "-webkit-fill-available",
      width: "100%",
      background: "white",
      display: "inline-block"
    },
    icon: {
      fill: ({ color }: Props) => {
        return color === "error" ? "#F5222D" : "#1890FF";
      }
    },
    info: {
      display: "block",
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "32px",
      color: "#262626",
      margin: "10px 0px 8px 0px"
    },
    subtitle: {
      display: "block",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#595959"
    }
  };
});

interface Props {
  icon: any;
  title: string;
  color?: "primary" | "error";
  subtitle?: string;
}
const InfoComponent: React.FC<Props> = props => {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <props.icon
        data-testid={"icon"}
        className={classes.icon}
        height="63"
        width="63"
      />
      <span className={classes.info}>{props.title}</span>
      {props.subtitle && (
        <span className={classes.subtitle}>{props.subtitle}</span>
      )}
    </div>
  );
};

export { InfoComponent };
